import {request} from "./network";
import store from "../store";

// 获取待定学生数据
export function getWaitList(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getWaitList",
        data,
    });
}

// 分档评分 (1-评分)
export function setYjWait(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjWait",
        data,
    });
}

// 获取分档数据
export function getYjScoreLevel(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/auth/getYjScoreLevel",
        data,
    });
}

