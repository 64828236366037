<template>
    <el-dialog
            title="提示"
            :visible.sync="messageDialog"
            :closeOnClickModal="false"
            v-loading="loading"
            width="420px"
            class="hint"
            :before-close="()=>onClose()"
            @open="onOpen">
        <div style="line-height: 28px;font-size: 16px;">
            {{dataObj.showMsg}}。
            <span v-if="$store.state.examInfo.exam_ts==1">点击【取消评分】不保存当前评分。</span>
            <span v-if="$store.state.examInfo.exam_ts==2">点击【仍要评分】，保存当前评分；点击【取消评分】则不保存当前评分。</span>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onClose()">取消评分</el-button>
            <el-button type="primary"
                       @click="onConfirm()"
                       v-if="$store.state.examInfo.exam_ts==2"
            >仍要评分
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {setYjScore, setYjScore2, setYjScoreEnd, updateYjScoreT, setYjScoreTs} from "../api/mark";
    import {updateYjScore, updateYjScore2} from "../api/score";

    export default {
        name: "MessageDialog",
        props: {
            dialogVisibleFu: Boolean,
            isUpdateFu: [Number, String],
            dataObjFu: Object,
        },
        data() {
            return {
                loading: false,
                messageDialog: this.dialogVisibleFu,
                dataObj: this.dataObjFu,
                isUpdate: this.isUpdateFu,
            }
        },
        methods: {
            onOpen() {
                setYjScoreTs({
                    score: this.dataObj.score,
                    id_card: this.dataObj.id_card
                })
                    .then(res => {
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            onClose(type = false) {
                let _this = this;
                _this.messageDialog = false;
                _this.$emit('closeDialog', this.messageDialog, type);
            },
            onConfirm() {
                let _this = this;
                _this.giveScore(_this.dataObj.id_card, _this.dataObj.score);
            },
            // 打分
            giveScore(id_card, scoresId) {
                if (this.isUpdate != 1) {
                    if (this.$store.state.teacherType == 1) {
                        if (this.isUpdate == 6) {
                            // 分档评分学生打分
                            this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 2)
                        } else {
                            this.giveScoreRequestHandle(updateYjScore, id_card, scoresId, 2)
                        }
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(updateYjScore2, id_card, scoresId)
                    }
                } else {
                    if (this.$store.state.teacherType == 1) {
                        this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 2)
                    } else if (this.$store.state.teacherType == 2) {
                        this.giveScoreRequestHandle(setYjScoreEnd, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(setYjScore2, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 4) {
                        if (!this.message) {
                            return this.$message.info('请填写改分理由')
                        }
                        this.giveScoreRequestHandle(updateYjScoreT, id_card, scoresId)
                    }
                }
            },
            // 打分请求方法
            giveScoreRequestHandle(api, id_card, scoresId, type = false) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let obj = {
                    score: scoresId,
                    id_card: id_card
                };
                if (this.$store.state.teacherType == 4) {
                    obj = {
                        score: scoresId,
                        id_card: id_card,
                        message: this.dataObj.message,
                    }
                }
                if (type) obj['qz'] = type;
                api(obj, type)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.$message.success(res.data.message);
                        this.onClose(true);
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
        },
        watch: {
            dialogVisibleFu() {
                this.messageDialog = this.dialogVisibleFu;
            },
            isUpdateFu() {
                this.isUpdate = this.isUpdateFu;
            },
            dataObjFu() {
                this.dataObj = this.dataObjFu;
            },
        }
    }
</script>

<style scoped lang="scss">
    .hint {
        ::v-deep .el-dialog {
            min-width: auto;
            max-width: inherit;
            height: inherit !important;

            .el-dialog__header {
                text-align: center;
            }

            .el-dialog__body {
                text-align: left;
            }

            .el-dialog__footer {
                text-align: center;

                .el-button + .el-button {
                    margin-left: 60px;
                }
            }
        }
    }
</style>
