import {request} from "./network";
import store from "../store";

// 获取待评阅学生数据
export function getStudentList(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentList",
        data,
    });
}
// 获取待仲裁数据
export function getStudentListEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentListEnd",
        data,
    });
}
// 获取第二轮回评学生数据
export function getStudentList2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentList2",
        data,
    });
}


// 给未评分的学生评分
export function setYjScore(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjScore",
        data,
    });
}

// 给该轮次第二轮的学生评分
export function setYjScore2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjScore2",
        data,
    });
}

// 给学生仲裁得分
export function setYjScoreEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjScoreEnd",
        data,
    });
}


// 阅卷结束
export function yjAssessNumEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/yjAssessNumEnd",
        data,
    });
}

// 二次阅卷结束
export function yjAssessNumEnd2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/yjAssessNumEnd2",
        data,
    });
}


// 获取待评阅学生数据（临时修改）
export function getStudentListT(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentListT",
        data,
    });
}

// 已打分的学生修改分数（临时修改）
export function updateYjScoreT(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/updateYjScoreT",
        data,
    });
}

// 打开message弹框-通知后端
export function setYjScoreTs(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjScoreTs",
        data,
    });
}
