<template>
    <div>
        <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                :before-close="()=>onClose()"
                @open="onOpen"
                class="giveMarkDialog">

            <div class="base-container give-mark">
                <el-card class="box-card">
                    <div class="item" v-loading="loading">
                        <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000"
                                     :initial-index="initialIndex"
                                     :autoplay="false"
                                     :loop="false" arrow="never"
                                     indicator-position="none">
                            <!--评阅模块-->
                            <el-carousel-item v-if="$store.state.teacherType==4" v-for="(item, index) in dataList"
                                              :key="index">
                                <div class="giveMark flex">
                                    <!-- left -->
                                    <div class="mark_left">
                                        <div class="imgBox dialog"
                                             :class="isBig?'img_big':'img_small' ">
                                            <el-image
                                                    fit="contain"
                                                    :src="$store.getters.getFilePath+item.images_new"
                                                    :preview-src-list="isBig?[$store.getters.getFilePath+item.images_new]:[]"
                                                    @click="toImgBig(1)"
                                            >
                                            </el-image>
                                            <div class="sign">新</div>
                                        </div>
                                        <div class="imgBox dialog" :class="!isBig?'img_big':'img_small'">
                                            <el-image
                                                    fit="contain"
                                                    :src="$store.getters.getFilePath+item.images"
                                                    :preview-src-list="!isBig?[$store.getters.getFilePath+item.images]:[]"
                                                    @click="toImgBig(2)"
                                            >
                                            </el-image>
                                            <div class="sign">旧</div>
                                        </div>
                                    </div>
                                    <!-- right -->
                                    <div class="mark_right">
                                        <!-- 科目名称 -->
                                        <!--<div class="generalReview aa">
                                            <div class="flex">
                                                <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                                <div class="item-box">作品总数: {{$store.state.score_all_sum}}</div>
                                            </div>
                                            <div class="flex">
                                                <div class="item-box">已经评阅（{{$store.state.score_sum}}）:</div>
                                                <div class="item-box item-box-r">
                                                    <el-progress :percentage="$store.state.score_sum_cost"></el-progress>
                                                </div>
                                            </div>
                                        </div>-->
                                        <!-- 历史评分 -->
                                        <div class="historyScore aa">
                                            <h4>历史评分</h4>
                                            <div class="content flex" v-for="(item2, index2) in item['old_data']"
                                                 :key="index2">
                                                <template v-if="index2=='初评'">
                                                    <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                        <div class="item-box-l">{{index2}}评分：</div>
                                                        <div class="item-box-r"
                                                             v-if="item2['assess_nums_list'].length>0">
                                                <span v-for="(item3, index3) in item2['assess_nums_list']"
                                                      :key="index3">{{item3.score}}</span>
                                                        </div>
                                                        <div class="item-box-r" v-else>
                                                            <span>-</span>
                                                        </div>
                                                    </div>
                                                    <div class="item-box flex flex-lf">
                                                        <div class="item-box-l">最终分：</div>
                                                        <div class="item-box-r" v-if="item2['end'].length>0">
                                                    <span v-for="(item3, index3) in item2['end']"
                                                          :key="index3">
                                                        {{item3.score=='0-100'?index2+'结果待定':item3.score}}
                                                    </span>
                                                        </div>
                                                        <div class="item-box-r" v-else>
                                                            <span>-</span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="index2=='复评'">
                                                    <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                        <div class="item-box-l">{{index2}}评分：</div>
                                                        <div class="item-box-r"
                                                             v-if="item2['old'].length>0">
                                                <span v-for="(item3, index3) in item2['old']"
                                                      :key="index3">{{item3.score}}</span>
                                                        </div>
                                                        <div class="item-box-r" v-else>
                                                            <span>-</span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <!-- 改分理由 -->
                                        <div class="aa">
                                            <h4>改分理由: </h4>
                                            <el-input
                                                    type="textarea"
                                                    v-model="message"
                                                    maxlength="50"
                                                    show-word-limit
                                                    placeholder="请填写改分理由"
                                            ></el-input>
                                        </div>
                                        <!-- 当前评分 -->
                                        <div class="nowScore aa">
                                            <h4>当前评分: {{item.grade}}</h4>
                                            <div class="nowScore-btn is-score">
                                                <el-button :class="{
                                                               'is-select':(item.grade==item2.score),
                                                               'is-block':(item2.score==100),
                                                           }"
                                                           v-for="(item2, index2) in item.scores" :key="index2"
                                                           @click="giveScore(item.id_card, item2.id)"
                                                           size="mini">
                                                    {{item2.score}}
                                                </el-button>
                                            </div>
                                        </div>

                                        <!--初评 或者 仲裁已评分无分数 或者 初评结果待定(next==35) 不显示跨档评分-->
                                        <div style="text-align: center;margin: 10px 0 10px 0;">
                                            <el-button type="primary"
                                                       @click="setOldScore(item.id_card, item.old_score)">
                                                保持原分
                                            </el-button>
                                            <el-button type="primary" @click="getOtherScore">跨档评分</el-button>
                                        </div>
                                        <!-- 跨档评分 -->
                                        <div v-if="isShowOtherScore" class="nowScore aa">
                                            <h4>跨档评分: {{item.grade}}</h4>
                                            <!--<div class="nowScore-btn is-score">
                                                <el-button :class="{
                                                               'is-select':(item.grade==item2.score),
                                                               'is-block':(item2.score==100),
                                                           }"
                                                           v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                           :key="index2"
                                                           @click="giveScore(item.id_card, item2.id)"
                                                           size="mini">
                                                    {{item2.score}}
                                                </el-button>
                                            </div>-->
                                            <div class="nowScore-btn is-score"
                                                 v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                 :key="index2">
                                                <el-button v-for="(item3, index3) in item2"
                                                           :key="index3"
                                                           :class="{
                                                               'is-select':(item.grade==item3.score),
                                                               'is-block':(item3.score==100),
                                                           }"
                                                           @click="giveScore(item.id_card, item3.id)"
                                                           size="mini">
                                                    {{item3.score}}
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                            <!--阅卷、第二次阅卷、仲裁模块-->
                            <el-carousel-item v-else v-for="(item, index) in dataList" :key="index">
                                <div class="giveMark flex">
                                    <!-- left -->
                                    <div class="mark_left">
                                        <div class="imgBox img_big dialog">
                                            <el-image
                                                    fit="contain"
                                                    :src="$store.getters.getFilePath+item.images"
                                                    :preview-src-list="[$store.getters.getFilePath+item.images]"
                                            >
                                            </el-image>
                                        </div>
                                    </div>
                                    <!-- right -->
                                    <div class="mark_right">
                                        <!-- 科目名称 -->
                                        <!--<div class="generalReview aa">
                                            <div class="flex">
                                                <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                                <div class="item-box">作品总数: {{$store.state.score_all_sum}}</div>
                                            </div>
                                            <div class="flex">
                                                <div class="item-box">已经评阅（{{$store.state.score_sum}}）:</div>
                                                <div class="item-box item-box-r">
                                                    <el-progress :percentage="$store.state.score_sum_cost"></el-progress>
                                                </div>
                                            </div>
                                        </div>-->
                                        <!-- 跨档评分 -->
                                        <!--v-if="$store.state.assess_num > 1 && isShowOtherScore"-->
                                        <div class="nowScore aa">
                                            <h4>评分: {{item.is_wait==0?item.grade:''}}</h4>
                                            <!--<div class="nowScore-btn is-score">
                                                <el-button v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                           :key="index2"
                                                           :class="{
                                                               'is-select':(item.is_wait==0&&item.grade==item2.score),
                                                               'is-block':(item2.score==100),
                                                           }"
                                                           @click="giveScore(item.id_card, item2.id)"
                                                           size="mini"
                                                           :disabled="loading"
                                                >
                                                    {{item2.score}}
                                                </el-button>
                                            </div>-->
                                            <div class="nowScore-btn is-score"
                                                 v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                 :key="index2">
                                                <el-button v-for="(item3, index3) in item2"
                                                           :key="index3"
                                                           :class="{
                                                               'is-select':(item.is_wait==0&&item.grade==item3.score),
                                                               'is-block':(item3.score==100),
                                                           }"
                                                           @click="giveScore(item.id_card, item3.id)"
                                                           size="mini"
                                                           :disabled="loading"
                                                >
                                                    {{item3.score}}
                                                </el-button>
                                            </div>
                                        </div>
                                        <div class="nowScore aa"
                                             v-if="$store.state.teacherType == 1 && (isUpdate ==1 ||isUpdate == 6)">
                                            <h4>分档: {{item.is_wait==1?item.grade:''}}</h4>
                                            <div class="nowScore-btn is-wait">
                                                <el-button v-for="(item2, index2) in $store.state.levelScoreRoteList"
                                                           :key="index2"
                                                           :class="{
                                                               'is-select':(item.is_wait==1&&item.grade==item2.score),
                                                               'is-block':(item2.score==100),
                                                           }"
                                                           @click="setWait(item.id_card, item2.id)"
                                                           size="mini"
                                                           :disabled="loading"
                                                >
                                                    {{item2.score}}
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
                    </div>
                </el-card>
            </div>
        </el-dialog>

        <!--message弹框-->
        <message-dialog
                :dialogVisibleFu="messageDialogFu"
                :isUpdateFu="isUpdate"
                :dataObjFu="msgObjFu"
                @closeDialog="closeMessageDialog"
        ></message-dialog>
    </div>
</template>

<script>
    import {
        getStudentList,
        getStudentListEnd,
        getStudentList2,
        setYjScore,
        setYjScoreEnd,
        setYjScore2,
        getStudentListT,
        updateYjScoreT
    } from "../api/mark";
    import {
        getStudentScoreList,
        getStudentScoreList2,
        getStudentScoreListEnd,
        updateYjScore,
        updateYjScore2
    } from "../api/score";
    import {
        getWaitList,
        setYjWait,
        getYjScoreLevel,
    } from "../api/wait";
    import {
        getYjScoreAll
    } from "../api/select";
    import MessageDialog from "./MessageDialog";
    import {mapMutations} from "vuex";

    export default {
        name: "GiveMarkDialog",
        components: {MessageDialog},
        props: {
            isTypeFu: [String, Number],
            queryObjFu: [Object],
            dialogVisibleFu: [Boolean]
        },
        data() {
            return {
                title: '作品编号：',
                dialogVisible: this.dialogVisibleFu,
                query: {
                    start: 1,
                    size: 1,
                },
                total: 0,
                dataList: [],
                loading: false,
                initialIndex: 0,
                isType: 0,// 1-作品评分 2-已评阅 3-分档评分
                isUpdate: 1, // 是否是修改 1-未评分 2-历史评阅(已评分无分数) 3-作品对比(已评分无分数) 4-已评分有分数 5-已评阅页面(无分数) 6-分档评分
                isShowOtherScore: false,// 是否显示跨档评分 true-显示 false-不显示
                message: null,// 改分理由
                isBig: true,// 图片放大
                messageDialogFu: false,// 控制message弹框显示与隐藏
                msgObjFu: {},
            }
        },
        methods: {
            ...mapMutations([
                "updateOtherScoreRoteList",
                "updateLevelScoreRoteList",
            ]),
            onOpen() {
                let query = this.queryObjFu;
                let queryKey = this.queryObjFu;
                if (queryKey && Object.keys(queryKey).length > 0) {
                    this.query.start = Number(query.start);
                    this.query.order = Number(query.order);
                    if (this.isTypeFu == 3) {
                        if (query.scores) {
                            this.query.scores = query.scores;
                        }
                        this.isUpdate = 6;
                    } else if (query.scores) {
                        this.query.scores = query.scores;
                        this.isUpdate = 4;
                    } else if (this.query.order == 2) {
                        this.isUpdate = 2;
                    } else {
                        this.isUpdate = 3;
                    }
                } else {
                    this.query.order = 1;
                    this.isUpdate = 5;
                }

                this.getList(true);
                if (!this.$store.state.otherScoreRoteList
                    || this.$store.state.otherScoreRoteList.length <= 0) {
                    this.getOtherScore();
                }
            },
            // 子组件触发，关闭message弹框
            closeMessageDialog(val, type) {
                this.messageDialogFu = val;
                if (type) {
                    this.setScoreNextHandle();
                }
            },
            onClose(type = false) {
                this.dialogVisible = false;
                this.$emit('closeDialog', this.dialogVisible, type);
            },
            toImgBig(type) {
                if ((type == 1 && this.isBig)
                    || (type == 2 && !this.isBig)) {
                    return false;
                }
                this.isBig = !this.isBig;
            },
            // 保持原分
            setOldScore(id_card, scoresId) {
                this.message = '保持原分';
                this.giveScoreRequestHandle(updateYjScoreT, id_card, scoresId)
            },
            // 获取评分数据(跨档评分)
            getOtherScore() {
                let _this = this;
                getYjScoreAll({})
                    .then(res => {
                        if (res.data.code != 200) {
                            return _this.$message.error(res.data.message);
                        }
                        _this.isShowOtherScore = true;
                        _this.updateOtherScoreRoteList(res.data.data.scores)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取分档数据列表
            getLevelScore() {
                let _this = this;
                getYjScoreLevel({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return _this.$message.error(res.data.message);
                        }
                        _this.updateLevelScoreRoteList(res.data.data.scores)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 分档评分
            setWait(id_card, scoresId) {
                console.log(id_card, scoresId, this.loading)
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let obj = {
                    id_card: id_card,
                    score: scoresId,
                    status: 1,
                }
                setYjWait(obj)
                    .then(res => {
                        console.log(res);
                        if (res.data.code != 200) {
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.isShowOtherScore = false;
                        this.message = null;
                        this.isBig = true;
                        this.loading = false;
                        this.onClose(2);
                        this.$message.success(res.data.message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.isChecked = false;
                    });
            },
            // 打分
            giveScore(id_card, scoresId) {
                if (this.isUpdate != 1) {
                    if (this.$store.state.teacherType == 1) {
                        if (this.isUpdate == 6) {
                            // 分档评分学生打分
                            this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 1)
                        } else {
                            this.giveScoreRequestHandle(updateYjScore, id_card, scoresId, 1)
                        }
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(updateYjScore2, id_card, scoresId)
                    }
                } else {
                    if (this.$store.state.teacherType == 1) {
                        this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 1)
                    } else if (this.$store.state.teacherType == 2) {
                        this.giveScoreRequestHandle(setYjScoreEnd, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(setYjScore2, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 4) {
                        if (!this.message) {
                            return this.$message.info('请填写改分理由')
                        }
                        this.giveScoreRequestHandle(updateYjScoreT, id_card, scoresId)
                    }
                }
            },
            // 打分请求方法
            giveScoreRequestHandle(api, id_card, scoresId, type = false) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let obj = {
                    score: scoresId,
                    id_card: id_card
                };
                if (this.$store.state.teacherType == 4) {
                    obj = {
                        score: scoresId,
                        id_card: id_card,
                        message: this.message
                    }
                }
                if (type) obj['qz'] = type;
                api(obj)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.loading = false;
                            if (res.data.code == 40000123
                                && this.$store.state.examInfo.exam_ts > 0) {
                                this.messageDialogFu = true;
                                this.msgObjFu = {
                                    score: scoresId,
                                    id_card: id_card,
                                    message: this.message,
                                    showMsg: res.data.message,
                                };
                                return false;
                            }
                            return this.$message.error(res.data.message);
                        }

                        this.setScoreNextHandle();
                        this.$message.success(res.data.message);
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
            // 打完分的操作
            setScoreNextHandle() {
                this.$store.dispatch('GenerateRoutes').then(response => {
                }).catch(error => {
                })
                this.isShowOtherScore = false;
                this.message = null;
                this.isBig = true;
                this.loading = false;
                this.onClose(1);
            },
            getList(type = false) {
                // false-打分跳转  true-非打分跳转
                if (this.isUpdate != 1) {
                    // 获取已评分数据列表
                    if (this.$store.state.teacherType == 1) {
                        if (this.isUpdate == 6) {
                            // 获取分档评分数据
                            this.getUpdateList(getWaitList, type);
                        } else {
                            this.getUpdateList(getStudentScoreList, type);
                        }
                    } else if (this.$store.state.teacherType == 3) {
                        this.getUpdateList(getStudentScoreList2, type);
                    } else if (this.$store.state.teacherType == 2) {
                        this.getUpdateList(getStudentScoreListEnd, type);
                    }
                } else {
                    // 获取未评分数据列表
                    if (this.$store.state.teacherType == 1) {
                        this.requestHandle(getStudentList, type)
                    } else if (this.$store.state.teacherType == 2) {
                        this.requestHandle(getStudentListEnd, type)
                    } else if (this.$store.state.teacherType == 3) {
                        this.requestHandle(getStudentList2, type)
                    } else if (this.$store.state.teacherType == 4) {
                        this.requestHandle(getStudentListT, type)
                    }
                }

            },
            // 获取已评分数据请求方法
            getUpdateList(api, type) {
                if (type) {
                    if (this.loading) {
                        return;
                    }
                    this.loading = true;
                }
                api(this.query)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.message = null;
                        this.isBig = true;
                        this.total = res.data.data.sum ? res.data.data.sum : res.data.data.count;
                        this.dataList = res.data.data.list;
                        if (this.dataList.length > 0) {
                            this.title = '作品编号：' + this.dataList[0]['number'];
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
                this.$forceUpdate();
            },
            // 获取列表数据请求方法
            requestHandle(api, type) {
                if (type) {
                    if (this.loading) {
                        return;
                    }
                    this.loading = true;
                }
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.message = null;
                        this.isBig = true;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.count;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.$forceUpdate();
            }
        },
        watch: {
            isTypeFu(val) {
                this.isType = val;
            },
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/giveMark";

    .giveMarkDialog {
        ::v-deep .el-dialog {
            margin-top: 15vh;
            width: 75vw;
            height: 75vh;
            min-width: 1000px;
            min-height: 600px;
            display: flex;
            flex-flow: column;

            .el-dialog__body {
                flex: 1;
            }
        }
    }
</style>
